.footer{
    display:flex;
    justify-content: space-between;
    
    height: 70vh;
    padding: 4%;
}
a {
    text-decoration: none; 
    color: grey; 
  }
  
  a:hover {
    cursor: pointer; 
  }

  .logo{
    width: 50%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
  }
  .terms{
    margin-top: 10%;
    gap: 30px;
    display: flex;
    flex-direction: row;
  }

  p{
  
    padding-top: 20px;
    
    text-align: center;
  }
  .link2{
    display: flex;
    flex-direction: column;
    text-align: end;
  }
  .link{
    text-align: left;
  }
  h5{
    font-weight: 500;
  }


  @media only screen and (max-width:768px){
    .footer{

      margin-left: 2%;
      width: 80vw;
      height: 60vh;
      
  }
  .logo{
    display:flex;
    
  }
    .logo img{
      scale: 0.4;
      margin-left: 10%;
    }
    
    .para{
      margin-left: 20%;
      font-size: x-small;
      width: 90vw;
    }
  }