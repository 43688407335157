.clients{
    width: 90%;
    height: 60vh;
    display:flex;
    padding-left:5%;
    
   
  
}
.clienttext{
   
    text-align: justify;
    h1{
        text-transform: uppercase;
        text-align: left;
    }
    
   
}
.clienttext h1{
    font-family: futura;
}
.clienttext p{
    line-height: 1.5rem;
  
    width: 80%;
    text-align: left;
}

@media only screen and (max-width:768px){
    .clients{
        height:100vh;
    }
    .clienttext p{
        line-height: 1.5rem;
      
        width: 100%;
        text-align: center;
    }

    .clienttext h1{
        text-align: center;
    }
}