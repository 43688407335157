.header {
  position: fixed;
  right: 50px;
  top: 50px;
  z-index: 999999;
}

.header .menu {
  position: relative;
  height: 650px;
  width: 480px;
  background-color: rgba(249, 248, 245, 0.8);

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px); /* For Safari and older versions of Chrome */
  -moz-backdrop-filter: blur(10px); /* For Firefox */
  -o-backdrop-filter: blur(10px); /* For Opera */
  -ms-backdrop-filter: blur(10px); /* For Internet Explorer */

  border-radius: 25px;
  z-index: 995;
}

@media only screen and (max-width:768px) {
  .header {
    right: 10%;
    top: 6%;
  }

  .header .menu {
    position: relative;
    height: 50vh;
    width: 40%;
    background-color: rgba(249, 248, 245, 0.8);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* For Safari and older versions of Chrome */
    -moz-backdrop-filter: blur(10px); /* For Firefox */
    -o-backdrop-filter: blur(10px); /* For Opera */
    -ms-backdrop-filter: blur(10px); /* For Internet Explorer */
  

    border-radius: 25px;
    z-index: 995;
    margin-left: 7%;
  }
}
