.navbar {
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    height: 100px;
    padding-top: 20px;
    padding-right: 10%;
    z-index: 999999999;
  
  }
  
  .navbar .nav1 img {
    position: fixed;
    z-index: 999;
  }
  
  .navbar .nav2 {
    padding-top: 20px;
  }



  .navbar .navbar-list {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .navbar .navbar-list .navbar-item {
    margin-right: 20px;
  }
  
  .navbar .navbar-list .navbar-item a {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;
  }
  
  .navbar .navbar-list .navbar-item a:hover {
    color: #A2A092;
  }
  
  @media only screen and (max-width: 768px) {
    .navbar {
      
      width: 90%;
      gap: 10%;
      overflow:hidden;
      height: 100px;
    }
  
    .navbar .nav1 img {
      position: static;
      scale:.8;
     
    }
  
    .navbar .navbar-list {
      padding: 0px;
      gap: 10%;
      margin-right: 2rem;
    }
  
    .navbar .navbar-list .navbar-item {
      margin-right: 0;
      margin-bottom: 10px;
    }
    .navbar .nav2 {
      padding-top: 5px;
      padding-right: 60px;
    }
    
  }
  