
.raju {
    width: 98%;
    height: auto;
    display: grid;
    overflow: hidden;
    margin: 0;
    list-style: none;
    gap: 20px;
    padding: 15px;
    border-radius: 20px;
    position: relative;
  }
  
  .balli {
    display: flex;
    
    border-radius: 2%;
    height: max-content;
    padding: 5%;
    background: rgb(209, 208, 203);
    background: linear-gradient(90deg, rgba(46, 46, 46, 0.8324579831932774) 0%, rgba(110, 99, 83, 1) 16%, rgba(209, 208, 203, 1) 46%);
  }
  
  .balli span {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .title-container {
    width: 30%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  
  .title {
    font-family: futura;
    margin: auto;
    text-transform: uppercase;
    font-size: 6vh;
  }
  
  .details-container {
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1rem;
    margin: auto;
    font-family: monospace;
  }
  
  .photocover {
    max-height: 400px;
    display: flex;
    justify-content: center;
  }
  
  .product-image {
    object-fit: cover;
    width: 50%;
  }
  
  .description {
    max-width: 80%;
    margin-top: 5%;
    font-family: Montserrat;
  }
  
  .features-list {
    margin-top: 4%;
  }
  
  .features-list li {
    font-family: Montserrat;
  }
  
  /* Responsive styles */
  @media only screen and (max-width: 768px) {
    
    .raju {
      grid-template-columns: 1fr;
      max-width: 90%;
     

    }
  
    .balli span {
      grid-column: span 1;
      flex-direction: column;
    }
    .balli {
        display: flex;
        flex-direction: column;
        background: rgb(209, 208, 203);
        background: linear-gradient(180deg, rgba(46, 46, 46, 0.8324579831932774) 0%, rgba(110, 99, 83, 1) 16%, rgba(209, 208, 203, 1) 46%);
        height: 80vh;
      
      }

      .title {
        
        font-size: 4vh;
        
      }
      .title-container {
        width: 80%;
        padding-top: 10%;
      }

      .details-container {
        max-width: 90%;
       
     
        font-size: 0.9rem;
       
      }
  }
  