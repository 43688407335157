.contact-button {
	z-index: 999999;
	position: fixed;
	bottom: 30px;
	right: 50px;
	height: 50px;
    width: 50px;
	background-color: #fff;
	
	
	
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 2px solid #00ff00;
    
   img{
    height: 50px;
   }
  }
  
  
  .contact-button:hover::before {
    content: 'Click to Connect';
    position: absolute;
    bottom: 120%; 
    left: 20%;
    transform: translateX(-100%);
    background-color: #fff;
    color: black;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: inline-block;
    white-space: nowrap;
  }

  
  @media only screen and (max-width:768px){
        .contact-button{
            height: 40px;
            width: 40px;

            img{
                height: 40px;
                width: 40px;
            }
        }
  }