.button {
    height: 40px;
    width: 100px;
    border-radius: 25px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    
  }
  
  .button .slider {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 999;

  }
  
  .button .slider .el {
    height: 100%;
    width: 100%;
    background-color: #A2A092;
    text-transform: uppercase;
    z-index: 999;
    
  }
  
  .button .slider .el:nth-of-type(2) {
    position: absolute;
    top: 100%;
    background-color: black;
    color: white;
  }
  
  .button .slider .el:hover .perspectivetext {
    transform: rotateX(90deg);
  }
  
  .button .slider .el:hover .perspectivetext p:nth-of-type(1) {
    transform: translateY(-100%);
    opacity: 0;
  }
  
  .button .slider .el:hover .perspectivetext p:nth-of-type(2) {
    opacity: 1;
  }
  
  .button .perspectivetext {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.75s cubic-bezier(0.76, 0, 0.24, 1);
    transform-style: preserve-3d;
    transform: translate(0, -10px);
  }
  
  .button .perspectivetext p:nth-of-type(2) {
    position: absolute;
    transform: rotateX(-90deg) translateY(9px);
    transform-origin: bottom center;
    opacity: 0;
    transition: all 0.75s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  @media only screen and (max-width:768px){
    .button {
      height: 30px;
      width: 80px;
    border-radius: 25px;
  

  }
  }