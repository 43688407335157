
body{
  width: 98vw;
  height: 100vh;
 
}


html{
  scroll-behavior:smooth;
}

