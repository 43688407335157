.nav {
    height: 50%;
    padding: 50px 40px 50px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .nav .body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .nav .body .linkcontainer {
    perspective: 120px;
  }
  
  .nav .body a {
    color: black;
    text-decoration: none;
    font-size: 25px;
  }
  
  .nav .footer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .nav .footer a {
    padding-top: 10%;
    width: 50%;
    color: black;
    font-size: 20px;
    text-decoration: none;
  }
  