


  .aboutmain{
    max-width: 95vw;
    margin-left: 2.5%;
    margin-top: 1%; 

  }
  
  .about {

      display: grid;
      grid-gap: 10px;
      grid-template-columns: 30% 30% 40%;
     
    }
  .logophoto{
    width:50%;
    padding-left:25%
  }
    .yaya {
      /* background-color: #444;
      color: #fff; */
      border-radius: 15px;
      
       
    }
  
    .A {
     height:160px;
      grid-column: 1 / span 2;
    }
    .B {
        
      grid-column: 3 ;
      grid-row: 1 / span 2;
    }
    .C {
        
      grid-column: 1 ;
      grid-row: 2 ;
    }
    .D {
      padding:0 25px 0  0 ;
      grid-column: 2 ;
      grid-row: 2 ;
    }






.who{
 
    
    margin-top: 1rem;
    
    font-family: futura;
    text-transform: uppercase;
    font-size: 160px;
    gap: 10;
    letter-spacing: -0.7rem;
    line-height: 8rem;
}
.intro{
    overflow-wrap: normal;
    text-align: left;
    width: 400px;
    font-size: 20px;
    transform: translate(-30px,-20px);
    line-height: 1.5rem;
    letter-spacing: 0px;
}


@media only screen and  (max-width:1026px){
    
    .about {

       
        grid-gap: 5px;
        grid-template-columns: 40% 20% 40%;
       
      }


    .who {
        
        font-size: 45px;
        letter-spacing: 0px;
        padding-top: 1rem;
        margin: 0.5rem;
        line-height: 1rem;
        
    }
    .intro{
        font-size: 10px;
    }

    .A {
        height:40px;
         grid-column: 1 / span 2;
       }

       .aboutmain{
        width:100%;
       }

       .intro{
        overflow-wrap: normal;
        text-align: left;
        width: 150px;
        font-size: 10px;
        transform: translate(-10px,0);
        line-height: .5rem;
        letter-spacing: 0px;
    }

    .logophoto{
        width:40%;
        padding-left:20%
      }
       
}


