.accordion-container {
    width: 100%; 
   
  }
 
  .accordion-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-family: futura;
    font-size: 70px;
    text-transform: uppercase;
    padding: 10px;
    background-color: #f7f2f1;
    
    
    margin-bottom: 5px;
    transition: background-color 0.8s ease;/* Smooth transition for background color */
  }
  
  .accordion-title.active {
    background-color: transparent;

  }
  
  .accordion-content {
    padding: 10px;
    height: 75vh;
   
   
    margin: 5px 0;
    display: none;
    transition: height 4s ease;

  }
  
  .accordion-content p {
    margin: 0;
   
  }
.insidecontent{
    display: flex;
    flex-direction: row;
    height: 75vh;
}
  .contenttext{
    
    padding: 5% 10%;
    font-size: 20px;
  }
  .contentanimation{
    width: 50%;
    padding-left: 2%;
  }
  .contentanimation img{
    height: 80%;
  }
  

 @media only screen  and (max-width:1024px){
  .accordion-container{
    width: 95vw;
    
  }
    .accordion-title{
        font-size:20px;
    }
    .accordion-content{
        height: 65vh;
        padding: 5px;
    }
    .insidecontent{
        height: 50vh;
        flex-direction: column;
    }
    .contenttext{
        font-size: 10px;
    }
    .contentanimation{
        width: 100px;
        padding-left: 2%;
    }
    .contentanimation img{
        height: 10%;
      }
 }