
  
  .contact-heading {
    font-size: 10rem;
    font-family: 'futura';
    text-transform: uppercase;
  }
  
  
  .info-text {
    font-size: 1rem;
    justify-self: center;
  }
  
  
  .contact-links {
    
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 2%;
  }
  .ContactHead{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  
  
  .contact-icon {
    height: 60px;
  }
  
  
  .google-map-code {
    margin: 2%;
  }
  
  
  .google-map-code iframe {
    width: 95vw;
    height: 450px;
    border: 0;
  }
  
  @media only screen and (max-width: 768px) {
    
    .contact-heading {
      font-size: 4rem;
      padding: 5%;
    }
  
    
    .info-text {
      padding: 5%;
      font-size: 0.8rem;
    }
  
    
    .contact-links {
      margin: 1%;
    }
  
   
    .contact-icon {
      height: 40px;
    }
  
   
    .google-map-code iframe {
      width: 100%;
      height: 300px;
      
    }
  }