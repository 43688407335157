




.main {
  max-width: 100vw;

  
  .page1 {
    padding-left: 2vw;
    width: 95vw;
    height: auto;
    position: relative;
   
   

    h1{
      font-size: 12vw;
      text-transform: uppercase;
      letter-spacing: -3px;
      line-height: 4vw;
      font-family: futura;
      
    }

    .videoContainer {
      height: 80vh;
      width: 100%;
      background: grey;
      margin-top: 1vw;
      position: relative;
      
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  
  }

  .tag-line {
    display: flex;
    justify-content: center; 
    align-items: center; 
    text-transform: uppercase;
    font-size: xx-large;
    margin-bottom: -10px;
  }
  
  .page2 {
    min-height: 100vh;
    max-width: 100vw;
   
    display: flex;
    flex-direction: row;
    
    align-items: center;
    
    justify-content: space-between;

    .elem {
      height: 100%;
      width: 32%;
      min-width: 31%;
      
      
      margin: 10px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: 100%;
        object-fit:cover;
        transform: scale(1.2); 
      }

      .labelle,
      .labelle1,
      .labelle2 {
        height: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        width: 70%;
        position: absolute;
        
        top: 50%;

        
      }

     

      .labelle {
        background-color: #fbd2d2;
      }

      .labelle1 {
        background-color: 		#fbd2d2;
      }

      .labelle2 {
        background-color: 		#fbd2d2;
      }
    }
  }


.labelle, .labelle1, .labelle2 h3{
  width: 100%;
  text-align: center;
  font-family:Arial, Helvetica, sans-serif;
}


.second{
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-items: space-between;
  
  
}
.product{
  width: 40%;
  padding-left: 10%;
 
}
.quote{
  width: 45%;
  padding-left: 2%;
  text-align: left;
  font-family: futura;
}
.second h1{
  text-transform: uppercase;
}
.product p{
  text-align: end;
  
}

.about{
  height: 80vh;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap:4rem;
  font-family: Arial, Helvetica, sans-serif;
 
}
.photowe img{
  height: 500px;
  width: auto;
}

.whoarewe{
  width: 30%;
 
}
.about p{
  text-align: left;
  line-height: 30px;
  
 
  font-size: large;

 
}
.about h2{
  
  font-family: futura;
  text-transform: uppercase;
  font-size:xxx-large;
  text-align: left;
 
}
}
@media only screen and (max-width:768px){
 .main{
  .videoContainer{
    margin-bottom: 20vh;
  }

  .about{
    
    height: 200vh;
    flex-direction: column;
    gap:1rem;
    
  }
  .whoarewe{
    width: 90%;
  }
  .about p{
    font-size: smaller;
    line-height: 15px;
  }
 
  .photowe img{
    height: 400px;
  }
  
 
  .page2{
   
    display: flex;
    flex-direction: column;
    
    .elem {
      height: 100vh;
      width: 100vw;
      min-width: 31%;
    }
  }
  
  

  .second{
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-items: space-between;
    
    
  }


  .quote{
    width: 100%;
    font-size: smaller;
     text-align: left;
     font-family: futura;
   }
   .product{
    width: 90%;
    text-align: end;
   }
 }
}