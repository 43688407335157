body {
  margin: 0;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'Futura';
  src: url('../public/Assets/Futura\ Bold\ Italic\ font.ttf') format('truetype');
  /* Add other formats if needed */
 

}