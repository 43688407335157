.page3 {
    min-height: 140vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
}

.child {
    height: 54vh;
    width: 38vw;
  
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.child img{
    height: 100%;
   
    
}

@media only screen and (max-width: 767px) {
    .page3 {
        min-height: 200vh;
        flex-direction: column; 
        align-items: center;
        justify-content: center; 

    }

    .child {
        width: 95%;

        height: 40vh;
        scale: 0.8;
       
       
    }
}